import React from 'react';
import { graphql } from 'gatsby';
import { Grid } from 'semantic-ui-react';
import Helmet from 'react-helmet';
import { PageLayout, CardNavigation, HeroElement, FoodMenu,  } from '@fishrmn/fishrmn-components';

import Layout from '../components/Layout';

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const imageData = this.props.data.allFishermanImages.edges[0].node;
    const data = { ...businessData, ...imageData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Menu</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={ 
              <HeroElement
                header={ "Menu" }
                tagline={ "" }
                images={ [ data.heroImages.map( ({url}) => url)[0] ] }
                showMap={ false }
                ctas={ [ data.ctas.phone ] }
                ctaInverted={ true }
                ctaColor={ "white" }
                containerColor={ "primary" }
                textColor={ "white" }
                showMultiple={ true }
                overlay={ true }
                fullWidth={ true }
                parallax={ false }
                containerAs={ "none" }
                height={ "100vh" }
                gutter={ false }
              />
           }
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={ "middle" }
            textAlign={ "left" }
          >
            <Grid.Column width={ 16 }>
              <FoodMenu
                header={''}
                centerNavigation
                categoryDisplayVariant="HorizontalCardRow"
                itemDisplayVariant="HorizontalCard"
                menu={{
                  schedules: data.menu.schedules,
                  categories: data.menu.categories,
                  items: data.menu.items,
                  modifierSets: data.menu.modifier_sets,
                  modifiers: data.menu.modifiers
                }}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          ctas {
            phone {
              link 
              text 
              internal 
            }
          }
          businessName 
          menu {
            categories {
              items
              name
              id
            }
            items {
              modifier_sets
              description
              id
              name
              variations {
                id
                menu_item
                name
                order
                price
              }
            }
            modifier_sets {
              id
              min_allowed
              modifiers
              name
            }
            modifiers {
              id
              name
              price
            }
            schedules {
              available_days
              categories
              id
              name
            }
          }
        }
      }
    }
    allFishermanImages {
      edges {
        node {
          galleryImages {
            url 
          }
          heroImages {
            url 
          }
        }
      }
    }
  }
`;